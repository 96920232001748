import Constants from 'expo-constants';
import { deviceName, deviceYearClass } from 'expo-device';
import { Platform } from 'react-native';

export const IS_TEST_RELEASE =
  process.env.IS_TEST_RELEASE === '1' ||
  Constants.expoConfig?.extra?.IS_TEST_RELEASE === '1';

export const DOMAIN = process.env.APP_DOMAIN;
export const DOMAIN_NEXT = process.env.APP_NEXT_DOMAIN;
export const DOMAIN_BRANCH = process.env.BRANCH_DOMAIN!;
export const DOMAIN_BRANCH_ALTERNATE = process.env.BRANCH_ALTERNATE_DOMAIN!;

export const NATIVE_DOMAIN = DOMAIN;

export const COLOR_NOTIFICATION_ICON = process.env.EXPO_NOTIFICATION_COLOR;
export const COLOR_PRIMARY = process.env.EXPO_PRIMARY_COLOR;
export const COLOR_SPLASH = process.env.EXPO_SPLASH_COLOR;

export const EXPO_IDENTIFIER = process.env.EXPO_IDENTIFIER!;
export const EXPO_VERSION = process.env.EXPO_VERSION!;
export const EXPO_SLUG = process.env.EXPO_SLUG!;
export const EXPO_NAME = process.env.EXPO_NAME!;
export const EXPO_BUILD_NUMBER = process.env.EXPO_BUILD_NUMBER!;

export const API_ENDPOINT = process.env.API_ENDPOINT!;
export const SPLASH_RESIZE_MODE = process.env.EXPO_SPLASH_RESIZE_MODE;

export const EXPERIENCE_SLUG = `@noticesound/${EXPO_SLUG || 'unknown'}`;

export const INTERNAL_PREFIXES = [DOMAIN, DOMAIN_NEXT]
  .filter(Boolean)
  .map((domain) => 'https://' + domain)
  .map((domain) => domain.toLocaleUpperCase())
  .concat([EXPO_IDENTIFIER + '://']);

if (__DEV__ && Platform.OS === 'web') {
  INTERNAL_PREFIXES.push('http://LOCALHOST:19006');
}

const likeness = `${Platform.OS} ${Platform.Version || 'unversioned'} like ${
  deviceName || 'NoName'
}`.replace(/[^-._A-z0-9]/g, '');
export const USER_AGENT = `${EXPO_IDENTIFIER}/${EXPO_VERSION} (${
  Platform.OS
}; ${
  deviceYearClass || 'no class'
}, ${likeness}) Expo/EAS (React-Native; Slug ${EXPO_SLUG}; Build ${EXPO_BUILD_NUMBER})`;

export const DISCOVERY_EVENT_ID = '076b5463-87bf-47f2-a629-000cd780602b';
export const SHARE_EVENT_ID = 'f54ca116-8681-4922-83b6-e7bb2ad8e0d4';

// GPVNL 2023-2024
export const SPECIAL_EVENT_GPVNL_INSTROOM_ID =
  '7da0186e-e09c-4009-8d8a-98f9f7cd159c';
export const SPECIAL_EVENT_GPVNL_VOORRONDE_ID =
  'cff066af-d0bb-419a-81fa-deb648d7cdf5'; // TODO: update to 2024
export const SPECIAL_EVENT_GPVNL_SEMI_A_ID =
  '5462baeb-7078-4246-bd47-2c661986f0f8'; // TODO: update to 2024
export const SPECIAL_EVENT_GPVNL_SEMI_B_ID =
  '8926f63c-e185-4182-9cb7-fef6b381accb'; // TODO: update to 2024
export const SPECIAL_EVENT_GPVNL_FINAL_ID =
  'a2b8c81d-e3ca-4461-b6bc-c3215216b9ac'; // TODO: update to 2024
export const SPECIAL_GPVNL_EVENTS = [
  SPECIAL_EVENT_GPVNL_INSTROOM_ID,
  SPECIAL_EVENT_GPVNL_VOORRONDE_ID,
  SPECIAL_EVENT_GPVNL_SEMI_A_ID,
  SPECIAL_EVENT_GPVNL_SEMI_B_ID,
  SPECIAL_EVENT_GPVNL_FINAL_ID,
];

export const BRANCH_API_KEY = process.env.BRANCH_API_KEY!;
export const CLIENT_ID_FACEBOOK = process.env.CLIENT_ID_FACEBOOK!;
export const CLIENT_ID_SPOTIFY = process.env.CLIENT_ID_SPOTIFY!;
export const CLIENT_ID_GOOGLE = Platform.select({
  web: process.env.CLIENT_ID_GOOGLE_WEB!,
  android: process.env.CLIENT_ID_GOOGLE_ANDROID!,
  ios: process.env.CLIENT_ID_GOOGLE_IOS!,
});
export const CLIENT_ID_GOOGLE_PROXY = process.env.CLIENT_ID_GOOGLE_WEB!;

export const SENTRY_DSN = process.env.SENTRY_DSN || undefined;
