import { useEffect, useState } from 'react';
import {
  RouteProp,
  useIsFocused,
  useLinkProps,
  useRoute,
} from '@react-navigation/native';
import { LinearGradient } from 'expo-linear-gradient';
import {
  Image,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  TouchableOpacity,
  View,
} from 'react-native';
import {
  ActivityIndicator,
  Appbar,
  Button,
  Portal,
  Text,
  TextInput,
  useTheme,
} from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useInaccurateTimestamp } from 'react-native-use-timestamp';
import { useGoBack } from '../components/BackHeader';
import { PressableSurface } from '../components/PressableSurface';
import {
  SPECIAL_EVENT_GPVNL_INSTROOM_ID,
  SPECIAL_EVENT_GPVNL_VOORRONDE_ID,
} from '../config';
import { useEvent } from '../events/useEvent';
import { defineTranslations, i18n } from '../locale';
import { prepareUrl } from '../navigation/LinkingConfiguration';
import { BACKGROUND_DARK, BLACK, PRIMARY_DARK, WHITE } from '../theming';
import { RootStackParamList } from '../types';
import { useThemedColor } from '../utils/useThemedColor';
import { IMAGE_PIXEL_RATIO, variantImageUrl } from '../utils/variants';
import { ApiNominalRanking, useEventRanking } from './useEventRanking';

defineTranslations({
  en: {
    app: {
      ranking: {
        title: 'Ranking',
        description:
          'Aid your favourite artist during the competition of the Mentos Grote Prijs van Nederland 2024. This ranking is updated every fifteen minutes.',

        share_dialog_title: 'Share this track',
        share_message:
          'I am interested in your opinion about {{title}}! {{url}}',

        details_title: 'Biography {{name}}',

        votes_count: '{{value}} votes',
        score: 'Score {{value}}',

        no_results: 'No artists found.',
        clear_filter: 'Clear search',

        actions: {
          vote: 'Vote',
          share: 'Share',
          close_details: 'Close details',
          filter: 'Search artist...',
        },
      },
    },
  },

  nl: {
    app: {
      ranking: {
        title: 'Ranking',
        description:
          'Help je favoriete artiest mee te doen aan de Mentos Grote Prijs van Nederland 2024. Deze ranking wordt elk kwartier bijgewerkt.',

        share_dialog_title: 'Deel deze track',
        share_message: 'Ik ben benieuwd wat je van {{title}} vindt! {{url}}',

        details_title: 'Biografie {{name}}',

        votes_count: '{{value}} stemmen',
        score: 'Score {{value}}',

        no_results: 'Er zijn geen artiesten gevonden.',
        clear_filter: 'Zoekveld leeg makens',

        actions: {
          vote: 'Stem',
          share: 'Delen',
          close_details: 'Sluit details',
          filter: 'Zoek artiest...',
        },
      },
    },
  },
});

export function RankingScreen() {
  const { top, bottom } = useSafeAreaInsets();

  const focused = useIsFocused();
  const { url } =
    useRoute<RouteProp<RootStackParamList, 'Ranking'>>().params ?? {};

  const { data: eventData, error: eventError } = useEvent(url, {
    enabled: focused,
  });
  const { data, error, isLoading, isFetched } = useEventRanking(
    url + '/rankings/nominal',
    'nominal',
    {
      enabled: focused,
      staleTime: 600,
    }
  );

  const timestamp = useInaccurateTimestamp({ every: 30 * 1000 });
  const started = Boolean(
    eventData?.event.start_at &&
      new Date(timestamp).getTime() >
        new Date(eventData?.event.start_at).getTime()
  );
  const finished = Boolean(
    eventData?.event.end_at &&
      new Date(timestamp).getTime() >
        new Date(eventData?.event.end_at).getTime()
  );

  const eventHref = eventData?.event._links.self.href || '';
  const open = started && !finished;
  const linkProps = useLinkProps({
    to: [`/events/${prepareUrl(eventHref)}`].filter(Boolean).join('?'),
  });

  const onGoBack = useGoBack();
  const textColor = useThemedColor('#222', '#DDD');
  const rankColor = useThemedColor('#000', '#FFF');
  const surfaceColor = useThemedColor('#FFF', BACKGROUND_DARK);

  return (
    <Portal.Host>
      <View style={{ flex: 1, position: 'relative', width: '100%' }}>
        <KeyboardAvoidingView
          style={{ flex: 1, flexDirection: 'column', justifyContent: 'center' }}
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        >
          <ScrollView
            style={{
              width: '100%',
              height: '100%',
              maxHeight: Platform.select({ web: '100vh', default: '100%' }),
            }}
            contentContainerStyle={{
              width: '100%',
              minHeight: '100%',
              alignSelf: 'center',
              overflow: 'hidden',
              paddingTop: top,
              paddingBottom: bottom,
            }}
          >
            <LinearGradient
              colors={['#5759FB', '#EF7B90', '#f19167' /*'#F5C111'*/]}
              locations={[0.3, 0.8, 1]}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              }}
            />

            <View
              style={{
                marginHorizontal: 'auto',
                width: '100%',
                maxWidth: 600,
                flex: 1,
                paddingTop: 90,
                paddingBottom: 32,
              }}
            >
              {isLoading || !data ? (
                <ActivityIndicator
                  size="large"
                  color="#FFF"
                  style={{ marginTop: 64 }}
                />
              ) : (
                <NominalRanking
                  ranking={data.ranking}
                  eventHref={eventHref}
                  textColor={textColor}
                  rankColor={rankColor}
                  surfaceColor={surfaceColor}
                />
              )}
            </View>
          </ScrollView>
        </KeyboardAvoidingView>

        <View
          style={{
            position: 'absolute',
            top: 0,
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <View
            style={{
              maxWidth: 600,
              marginHorizontal: 'auto',
              width: '100%',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingHorizontal: 36,
              paddingTop: top + 12,
            }}
          >
            <Appbar.BackAction
              size={24}
              color="#fff"
              onPress={onGoBack}
              style={{
                borderRadius: 54 / 2,
                backgroundColor: `${PRIMARY_DARK}40`,
                width: 42,
                height: 42,
                marginLeft: -2,
              }}
            />
            <Button
              mode="contained"
              buttonColor="#F5C100"
              textColor="#000"
              disabled={!open}
              labelStyle={{ fontWeight: '500' }}
              contentStyle={{ flexDirection: 'row-reverse', height: 44 }}
              icon="arrow-right"
              {...(open ? linkProps : undefined)}
            >
              {i18n.translate('app.ranking.actions.vote')}
            </Button>
          </View>
        </View>
      </View>
    </Portal.Host>
  );
}

function NominalRanking({
  ranking,
  eventHref,
  textColor,
  rankColor,
  surfaceColor,
}: ApiNominalRanking & {
  eventHref: string;
  textColor: string;
  rankColor: string;
  surfaceColor: string;
}) {
  // Filter entries
  const [filter, setFilter] = useState('');
  const showFilter = ranking._embedded.length > 0;
  const onChangeFilter = (value: string) => {
    setFilter(value);
  };
  const [entries, setEntries] = useState(ranking._embedded);

  useEffect(() => {
    const update = () => {
      const filterWords = filter
        .toLowerCase()
        .trim()
        .split(' ')
        // Unique
        .filter((v, i, self) => {
          return i === self.indexOf(v);
        });

      setEntries(
        filter
          ? ranking._embedded
              .filter((entry) =>
                filterWords.every(
                  (word) =>
                    entry._embedded.artist.name.toLowerCase().includes(word) ||
                    entry.title.toLowerCase().includes(word)
                )
              )
              .sort((a, b) =>
                a._embedded.artist.name
                  .toLowerCase()
                  .startsWith(filterWords[0]) ||
                (a.title.toLowerCase().startsWith(filterWords[0]) &&
                  !b._embedded.artist.name
                    .toLowerCase()
                    .startsWith(filterWords[0]))
                  ? -1
                  : b._embedded.artist.name
                      .toLowerCase()
                      .startsWith(filterWords[0]) ||
                    b.title.toLowerCase().startsWith(filterWords[0])
                  ? 1
                  : 0
              )
          : ranking._embedded
      );
    };
    const timeout = setTimeout(update, 200);
    return () => {
      clearTimeout(timeout);
    };
  }, [filter, ranking._embedded]);

  return (
    <View style={{ paddingHorizontal: 32 }}>
      <Text
        variant="titleLarge"
        style={{ color: 'white', fontSize: 32, lineHeight: 48 }}
      >
        {i18n.translate('app.ranking.title')}
      </Text>
      <Text
        variant="bodyLarge"
        style={{
          color: 'white',
          marginTop: 8,
          fontWeight: '300',
          marginBottom: 64,
        }}
      >
        {i18n.translate('app.ranking.description')}
      </Text>

      {/* Filter */}
      {showFilter && (
        <TextInput
          style={{
            backgroundColor: WHITE,
            marginTop: -20,
            marginBottom: 20,
          }}
          onChangeText={onChangeFilter}
          value={filter}
          placeholder={i18n.translate('app.ranking.actions.filter')}
          right={<TextInput.Icon icon="magnify" />}
          textColor={BLACK}
        />
      )}

      {/* No entries/clear filter */}
      {entries.length === 0 && filter !== '' && (
        <View style={{ flex: 1, flexDirection: 'row' }}>
          <Text style={{ marginRight: 3, fontSize: 16, color: WHITE }}>
            {i18n.translate('app.ranking.no_results')}
          </Text>

          <TouchableOpacity onPress={() => setFilter('')}>
            <Text
              style={{
                textDecorationLine: 'underline',
                padding: 5,
                marginTop: -5,
                fontSize: 16,
                color: WHITE,
              }}
            >
              {i18n.translate('app.ranking.clear_filter')}
            </Text>
          </TouchableOpacity>
        </View>
      )}

      {entries.map((entry) => {
        return (
          <RankingItem
            key={entry._links.self.href}
            entry={entry}
            eventHref={eventHref}
            textColor={textColor}
            rankColor={rankColor}
            surfaceColor={surfaceColor}
          />
        );
      })}
    </View>
  );
}

function RankingItem({
  entry,
  eventHref,
  textColor,
  rankColor,
  surfaceColor,
}: {
  entry: ApiNominalRanking['ranking']['_embedded'][number];
  eventHref: string;
  textColor: string;
  rankColor: string;
  surfaceColor: string;
}) {
  const uri = variantImageUrl(entry._links.cover?.href, 'thumbnail');
  const linkProps = useLinkProps({
    to: [
      `/events/${prepareUrl(eventHref)}/ranking/${prepareUrl(
        entry._links.self.href
      )}`,
    ]
      .filter(Boolean)
      .join('?'),
  });

  const winner = isWinner(entry, eventHref);

  return (
    <PressableSurface
      style={{
        borderRadius: 8,
        marginTop: 8,
        elevation: 2,
        backgroundColor: surfaceColor,
      }}
      linkProps={eventHref ? linkProps : undefined}
    >
      <View
        style={{
          flexDirection: 'row',
          paddingVertical: 8,
        }}
      >
        <View style={{ width: 56, justifyContent: 'center' }}>
          <Text
            style={{
              color: rankColor,
              fontWeight: '600',
              fontSize: 24,
              textAlign: 'center',
            }}
          >
            {entry.ranked ? entry.rank : '-'}
          </Text>
          {winner ? (
            <View
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                width: 56,
              }}
            >
              <Image
                source={require('../../assets/winner.png')}
                style={{ width: 14, height: 14 }}
                resizeMode="contain"
              />
            </View>
          ) : null}
        </View>
        <View style={{ width: 44, marginRight: 12 }}>
          <Image
            source={{
              uri,
              width: IMAGE_PIXEL_RATIO,
              height: IMAGE_PIXEL_RATIO,
            }}
            style={{
              width: 44,
              height: 44,
              borderRadius: 4,
              backgroundColor: '#222',
            }}
          />
        </View>

        <View style={{ justifyContent: 'center', paddingRight: 12, flex: 1 }}>
          <Text
            variant="bodySmall"
            style={{ color: textColor, fontWeight: '300', fontSize: 14 }}
            numberOfLines={1}
          >
            {entry._embedded.artist.name}
          </Text>
          <Text
            variant="bodySmall"
            style={{ color: textColor, fontWeight: 'bold', fontSize: 14 }}
            numberOfLines={1}
          >
            {entry.title}
          </Text>
        </View>
      </View>
    </PressableSurface>
  );
}

function isWinner(
  entry: ApiNominalRanking['ranking']['_embedded'][number],
  eventHref: string
) {
  if (!entry.ranked || !entry.score || !entry.rank) {
    return false;
  }

  if (eventHref.includes(SPECIAL_EVENT_GPVNL_INSTROOM_ID)) {
    return entry.rank < 4;
  }

  if (eventHref.includes(SPECIAL_EVENT_GPVNL_VOORRONDE_ID)) {
    return entry.rank < 11;
  }
}
