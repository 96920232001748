import { RouteProp, useIsFocused, useRoute } from '@react-navigation/native';
import Color from 'color';
import * as ScreenOrientation from 'expo-screen-orientation';
import {
  Fragment,
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Platform, StatusBar, View } from 'react-native';
import {
  ActivityIndicator,
  Appbar,
  HelperText,
  ThemeProvider,
  useTheme,
} from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useQueryClient } from 'react-query';
import { useUpdateProfile } from '../account/useUserProfile';
import { AnimatedBackgroundView } from '../components/AnimatedBackgroundView';
import { useDialog } from '../components/DialogContextProvider';
import { ErrorScreen } from '../components/ErrorScreen';
import { LoadingScreen } from '../components/LoadingScreen';
import { useSnackbar } from '../components/SnackbarProvider';
import {
  SPECIAL_EVENT_GPVNL_INSTROOM_ID,
  SPECIAL_EVENT_GPVNL_VOORRONDE_ID,
} from '../config';
import { defineTranslations, i18n } from '../locale';
import { prepareUrl } from '../navigation/LinkingConfiguration';
import { goToPath, resetToPath } from '../navigation/utils';
import { MediaPlayer } from '../player/MediaPlayer';
import { ResponsiveSwipeExperience } from '../swipe/SwipeExperience';
import {
  BASE_THEME,
  DARK_PURPLE,
  DARK_THEME,
  PASTEL_PINK,
  PRIMARY_DARK,
} from '../theming';
import { RootStackParamList } from '../types';
import { useValidColor } from '../utils/useValidColor';
import { isDiscoveryEvent, isGrotePrijsEvent } from './isSpecialEvent';
import { ApiEvent, useEvent } from './useEvent';
import { useVoteExperience } from './useVoteExperience';

defineTranslations({
  en: {
    app: {
      event: {
        progress: 'Track {{current}} of {{total}}',
        progress_binder: 'of',
        toast_done: "You've listened to all the tracks in {{title}}",
      },

      gpvnl: {
        dialog_done: {
          title: 'Win live show tickets',
          description:
            'Thank you for voting on évery act. You can rewatch the acts via favorites.\n\n\nWould you like to win a Mentos package including tickets to one of the live shows (April 8th and 15th)? \n\nPay attention to your email. If you consent, we will send you a one-time message if you are one of the winners, within 48 hours after voting concludes.',
          actions: {
            positive: { label: 'I consent!' },
            negative: { label: 'No thank you' },
          },
        },
      },
    },
  },

  nl: {
    app: {
      event: {
        progress: 'Track {{current}} van {{total}}',
        progress_binder: 'van',
        toast_done: 'Je hebt naar alle tracks in {{title}} geluisterd',
      },

      gpvnl: {
        dialog_done: {
          title: 'Win live show tickets',
          description:
            'Bedankt voor het stemmen op élke act. Je kan de acts terugkijken via favorieten.\n\n\nWil jij kans maken op een Mentos pakket inclusief tickets voor één van de liveshows (8 en 15 april)? \n\nAls je ons toestemming geeft ontvang je eenmalig een email bericht als je wint maximaal 48 uur nadat de stemmen zijn geteld.',
          actions: {
            positive: { label: 'Ik doe mee!' },
            negative: { label: 'Nee bedankt' },
          },
        },
      },
    },
  },
});

export function EventScreen() {
  const focused = useIsFocused();
  const { url } =
    useRoute<RouteProp<RootStackParamList, 'Event'>>().params ?? {};
  const { data, error, isLoading, isFetched } = useEvent(url, {
    enabled: focused,
  });

  const [locked, setLocked] = useState(false);

  useLayoutEffect(() => {
    let timer: undefined | number = undefined;
    ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT)
      .catch(() => {})
      .then(() => {
        timer = setTimeout(() => setLocked(true), 1000) as unknown as number;
      });

    return () => {
      ScreenOrientation.unlockAsync().catch(() => {});
      clearTimeout(timer);
    };
  });

  const isDiscovery = isDiscoveryEvent(data);
  const isVideoEnabled = isGrotePrijsEvent(data);

  if (!data || !locked) {
    if (isLoading || !isFetched || !locked) {
      return <LoadingScreen />;
    }

    if (error) {
      return <ErrorScreen error={error} />;
    }

    return (
      <ErrorScreen error={new Error('Expected an event, but got nothing')} />
    );
  }

  return (
    <ThemedEventExists
      event={data.event}
      background={isDiscovery ? undefined : data.event.background_color}
      primary={isDiscovery ? undefined : data.event.primary}
      fixedTheme={!!isDiscovery}
      videoEnabled={isVideoEnabled}
    />
  );
}

function ThemedEventExists({
  event,
  background,
  primary,
  fixedTheme,
  videoEnabled,
}: {
  background?: string;
  primary?: string;
  fixedTheme: boolean;
  videoEnabled: boolean;
} & ApiEvent) {
  const {
    colors: { surface: oldSurface, secondary: oldSecondary },
  } = useTheme();

  const newPrimary = useValidColor(primary, PASTEL_PINK);
  const newSurface = useValidColor(background, oldSurface);
  const newSurfaceIsDark = useMemo(
    () => new Color(newSurface).isDark(),
    [newSurface]
  );
  const nextTheme = newSurfaceIsDark ? DARK_THEME : BASE_THEME;
  const newText = useValidColor(
    primary,
    newSurfaceIsDark ? PASTEL_PINK : DARK_PURPLE
  );

  const playerRef = useRef<MediaPlayer>(null);

  return (
    <AnimatedBackgroundView
      style={{ width: '100%', flex: 1, elevation: 0 }}
      from={useValidColor('', oldSurface)}
      animate={videoEnabled ? '#111' : newSurface}
    >
      <ThemeProvider
        theme={useTheme({
          ...nextTheme,
          colors: fixedTheme
            ? nextTheme.colors
            : {
                ...nextTheme.colors,
                primary: newPrimary,
                surface: newSurface,
                onSurface: newText,
              },
        })}
      >
        <MediaPlayer ref={playerRef}>
          <EventVoteExperience
            event={event}
            fixedTheme={fixedTheme}
            playerRef={playerRef}
            videoEnabled={videoEnabled}
          />
        </MediaPlayer>
      </ThemeProvider>
    </AnimatedBackgroundView>
  );
}

function EventVoteExperience({
  event,
  fixedTheme,
  playerRef,
  videoEnabled,
}: {
  fixedTheme?: boolean;
  playerRef: RefObject<MediaPlayer | null>;
  videoEnabled?: boolean;
} & ApiEvent) {
  const queryClient = useQueryClient();
  const { cursor } = (useRoute().params as any) || { cursor: undefined };

  const onVerificationRequired = useCallback(() => {
    queryClient.invalidateQueries([i18n.locale, event._links.self.href]);
    queryClient.invalidateQueries([event._links.self.href]);
    queryClient.invalidateQueries([i18n.locale, event._links.tracks.href]);
    queryClient.invalidateQueries([event._links.tracks.href]);

    const path = [
      `/verifications/${prepareUrl(event._links.self.href)}`,
      cursor ? `cursor=${encodeURIComponent(cursor)}` : undefined,
    ]
      .filter(Boolean)
      .join('?');

    resetToPath(path);
  }, []);

  const onExplanationRequired = useCallback(() => {
    resetToPath('/getting-started/how-it-works');
  }, []);

  const eventIsGPvNL = isGrotePrijsEvent({ event });

  const { track, loading, hrefs, error, done, ...experience } =
    useVoteExperience(
      [
        event._links.tracks.href,
        cursor ? `cursor=${encodeURIComponent(cursor)}` : undefined,
      ]
        .filter(Boolean)
        .join(event._links.tracks.href.includes('?') ? '&' : '?'),
      playerRef,
      false,
      onVerificationRequired,
      onExplanationRequired
    );

  const brandingSrc = event._links.self.href.endsWith(
    SPECIAL_EVENT_GPVNL_INSTROOM_ID
  )
    ? require('../../assets/gpvnl-wildcards-logo.png')
    : eventIsGPvNL
    ? require('../../assets/gpvnl-logo.png')
    : null;
  const brandingSize = event._links.self.href.endsWith(
    SPECIAL_EVENT_GPVNL_INSTROOM_ID
  )
    ? { width: 200, height: 32 }
    : eventIsGPvNL
    ? { width: 147, height: 52 }
    : null;

  const { show: showSnackbar } = useSnackbar();
  const { show: showDialog } = useDialog();

  const {
    profileRef,
    prepare,
    mutation: { mutateAsync: updateProfile },
  } = useUpdateProfile();

  useEffect(() => {
    if (!done) {
      return;
    }

    queryClient.cancelQueries(event._links.self.href);
    queryClient.cancelQueries(['tracks', i18n.locale, event._links.self.href]);
    queryClient.invalidateQueries(event._links.self.href);
    queryClient.invalidateQueries([
      'tracks',
      i18n.locale,
      event._links.self.href,
    ]);

    setTimeout(
      () =>
        goToPath(
          `/discover?toast=done&source_url=${prepareUrl(
            event._links.self.href
          )}`
        ),
      0
    );

    setTimeout(
      eventIsGPvNL &&
        event._links.self.href.includes(SPECIAL_EVENT_GPVNL_VOORRONDE_ID)
        ? () => {
            showDialog('gpvnl.win', {
              title: i18n.translate('app.gpvnl.dialog_done.title'),
              content: i18n.translate('app.gpvnl.dialog_done.description'),
              positive: {
                label: i18n.translate(
                  'app.gpvnl.dialog_done.actions.positive.label'
                ),
                async onPress() {
                  const { current: profile } = profileRef;
                  if (profile) {
                    profile.consent.push(
                      'gpvnl.participation-price.2023-03-12'
                    );

                    const patch = prepare({ next: profile });
                    if (patch) {
                      return updateProfile({ patch }).catch(() => {});
                    }
                  }
                },
              },
              negative: {
                label: i18n.translate(
                  'app.gpvnl.dialog_done.actions.negative.label'
                ),
              },
            });
          }
        : () =>
            showSnackbar(
              i18n.translate('app.event.toast_done', {
                title: event._links.self.title,
              }),
              null
            ),
      0
    );
  }, [done]);

  if (!track || !(hrefs.stream || hrefs.wave) || loading) {
    if (error) {
      return (
        <Fragment>
          <HelperText
            type="error"
            style={{ alignSelf: 'center', textAlign: 'center' }}
          >
            {error.message}
          </HelperText>
          <Header videoEnabled={videoEnabled} />
        </Fragment>
      );
    }

    return (
      <Fragment>
        <View
          style={{
            paddingTop: 32,
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <ActivityIndicator size="large" />
        </View>
        <Header videoEnabled={videoEnabled} />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <View
        style={{
          paddingTop: videoEnabled ? 0 : 32,
          maxWidth: 800,
          width: '100%',
          marginHorizontal: 'auto',
          alignSelf: 'center',
          position: 'relative',
          zIndex: 0,
        }}
      >
        <ResponsiveSwipeExperience
          track={track}
          {...experience}
          allowSeek
          fixedTheme={fixedTheme}
          transparentTheme={videoEnabled}
          playerRef={playerRef}
          videoEnabled={videoEnabled}
          brandingSrc={brandingSrc}
          brandingSize={brandingSize}
          Header={
            videoEnabled
              ? () => (
                  <Header
                    videoEnabled={videoEnabled}
                    completed={experience.completed}
                    total={experience.total}
                  />
                )
              : null
          }
        />
      </View>

      {videoEnabled ? null : (
        <Header
          videoEnabled={videoEnabled}
          completed={experience.completed}
          total={experience.total}
        />
      )}
    </Fragment>
  );
}

function Header({
  videoEnabled,
  completed,
  total,
}: {
  videoEnabled?: boolean;
  completed?: number;
  total?: number;
}) {
  const { top } = useSafeAreaInsets();

  return (
    <View
      style={{
        position: 'absolute',
        top: 0,
        width: '100%',
        justifyContent: 'center',
        zIndex: 1,
      }}
    >
      <Appbar.Header
        style={{
          zIndex: 1,
          maxWidth: 800,
          margin: 'auto',
          width: '100%',

          backgroundColor: 'transparent',
          elevation: 0,
          paddingHorizontal: 36,
          paddingLeft: 32,
          height: 52 + 36,
        }}
        statusBarHeight={Platform.select({
          ios: StatusBar.currentHeight || top || 20,
          default: undefined,
        })}
      >
        <Appbar.BackAction
          size={24}
          color={videoEnabled ? '#000' : '#fff'}
          onPress={() => resetToPath('/discover')}
          style={{
            borderRadius: 54 / 2,
            backgroundColor: videoEnabled ? `#FFFFFF` : `${PRIMARY_DARK}40`,
            width: 42,
            height: 42,
            marginLeft: -2,
            marginRight: 16,
          }}
        />
        {videoEnabled && completed !== undefined && total !== undefined ? (
          <Appbar.Content
            title={String(
              i18n.translate('app.event.progress', {
                current: completed + 1,
                total,
              }) || ''
            )}
            style={{
              backgroundColor: '#FFF',
              borderRadius: 16,
              flex: 1,
              maxWidth: 400,
              paddingHorizontal: 16,
              paddingVertical: 8,
            }}
            titleStyle={{ color: '#000', textAlign: 'center', fontSize: 16 }}
          />
        ) : null}
      </Appbar.Header>
    </View>
  );
}
