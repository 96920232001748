import { useLinkProps } from '@react-navigation/native';
import { Platform, StyleSheet, View, Image } from 'react-native';
import { Button, Surface, Text, useTheme } from 'react-native-paper';
import { useInaccurateTimestamp } from 'react-native-use-timestamp';
import { AnimatedImage } from '../components/AnimatedImage';
import { ApiEvent } from '../events/useEvent';
import { ApiEventProgress } from '../events/useEventProgress';
import { defineTranslations, i18n } from '../locale';
import { prepareUrl } from '../navigation/LinkingConfiguration';
import { BLACK, DARK_PURPLE, PRIMARY_LIGHT, WHITE } from '../theming';
import { sanitizeColor } from '../utils/sanitzeColor';
import { useLerpWidth } from '../utils/useLerpWidth';
import { IMAGE_PIXEL_RATIO, variantImageUrl } from '../utils/variants';

const GPVNL_DISABLED = false;
const GPVNL_RANKING_DISABLED = false;

// Mentos
const SPONSOR_BLUE = '#2C2D82';
const SPONSOR_YELLOW = '#FEFD7E';

// Dutch only month label
const MONTH_LABEL = [
  'januari',
  'februari',
  'maart',
  'april',
  'mei',
  'juni',
  'juli',
  'augustus',
  'september',
  'oktober',
  'november',
  'december',
];

defineTranslations({
  en: {
    app: {
      gpnl_card: {
        actions: {
          goto_ranking: 'Ranking',
          goto_timeline: 'Timeline',
          goto_vote: 'Vote',
        },
      },
    },
  },

  nl: {
    app: {
      gpnl_card: {
        actions: {
          goto_ranking: 'Ranking',
          goto_timeline: 'Tijdlijn',
          goto_vote: 'Stem',
        },
      },
    },
  },
});

export function GrotePrijsVanNederlandCard({
  event,
  progress,
  cursor,
}: {
  progress: ApiEventProgress | undefined;
  skeleton?: string;
  cursor?: string;
} & ApiEvent) {
  const { dark } = useTheme();

  const backgroundColor = sanitizeColor(
    event.background_color,
    dark ? DARK_PURPLE : '#FFF'
  );
  const primaryColor = sanitizeColor(event.primary, PRIMARY_LIGHT);
  const textColor = '#FFF';

  const linkProps = useLinkProps({
    to: [
      `/events/${prepareUrl(event._links.self.href)}`,
      cursor ? `cursor=${encodeURIComponent(cursor)}` : undefined,
    ]
      .filter(Boolean)
      .join('?'),
  });

  const rankingLinkProps = useLinkProps({
    to: [`/events/${prepareUrl(event._links.self.href)}/ranking`]
      .filter(Boolean)
      .join('?'),
  });

  const timelineLinkProps = useLinkProps({
    to: '/gpvnl/timeline',
  });

  const eventStart = event.start_at ? new Date(event.start_at) : null;
  const eventEnd = event.end_at ? new Date(event.end_at) : null;

  const timestamp = useInaccurateTimestamp({ every: 30 * 1000 });
  const started = Boolean(
    eventStart && new Date(timestamp).getTime() > eventStart.getTime()
  );
  const finished = Boolean(
    eventEnd && new Date(timestamp).getTime() > eventEnd.getTime()
  );

  const open = started && !finished;

  // Force Dutch locale, use 'default' to use device locale
  const dateLocale = 'nl-NL';
  const startDateStr = eventStart
    ? `${eventStart.getDate()} ${MONTH_LABEL[eventStart.getMonth()]}`
    : '';

  const endDateStr = eventEnd
    ? `${eventEnd.getDate()} ${MONTH_LABEL[eventEnd.getMonth()]}`
    : '';

  // 375 designed width
  //
  // title:
  // - font size 30
  // - line height 34
  // - margin bottom 32
  //
  // description:
  // - font size 16
  // - line height 22

  const lerpWidth = useLerpWidth([315, 0], [375, 1]);
  const imageHeight = useLerpWidth([315, 60], [375, 80]);
  const marginBottom = useLerpWidth([386, 0], [436, 12]);

  const buttonIconSize = 20 + lerpWidth * 2; // target 22

  const hasSponsor =
    event.subtitle?.toLocaleLowerCase().includes('wildcards') ||
    event.subtitle?.toLocaleLowerCase() === 'eerste ronde';

  const colors = hasSponsor
    ? {
        eventInfo: SPONSOR_BLUE,
        secondaryButton: SPONSOR_BLUE,
        description: SPONSOR_BLUE,
        textShadow: false,
        primaryButton: SPONSOR_YELLOW,
      }
    : {
        eventInfo: '#000',
        secondaryButton: '#000',
        description: '#fff',
        textShadow: true,
        primaryButton: '#F5C100',
      };

  const timelineDisabled = GPVNL_DISABLED;
  const rankingDisabled = !open || GPVNL_DISABLED || GPVNL_RANKING_DISABLED;
  const votingDisabled = !open || GPVNL_DISABLED;

  return (
    <View
      style={{
        width: '100%',
        overflow: 'visible',
        marginBottom: 16,
      }}
    >
      <Surface
        theme={{ roundness: 26 / 4 }}
        style={{
          overflow: 'hidden',
          width: '100%',
          backgroundColor: dark ? DARK_PURPLE : '#666',
          borderRadius: 10,
          opacity: 1,
        }}
        elevation={1}
      >
        <BackgroundImage
          src={event?._links?.background_image?.href}
          color={backgroundColor}
          cover
        />

        <View
          style={{
            padding: 20,
            maxHeight: '100%',
            maxWidth: '100%',
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          {event?._links?.foreground_image?.href ? (
            <View
              style={{
                width: '100%',
                maxWidth: '100%',
                position: 'relative',
                height: imageHeight,
                maxHeight: 150,
                marginBottom,
              }}
            >
              <ForegroundImage
                src={event?._links?.foreground_image?.href}
                alt={[event._links.self.title, event.subtitle]
                  .filter(Boolean)
                  .join(' - ')}
              />
            </View>
          ) : (
            <Text
              variant="titleMedium"
              numberOfLines={2}
              style={{
                letterSpacing: 0.2,
                maxWidth: 400,
                marginRight: 72,
                minHeight: 40,
                includeFontPadding: false,
                color: textColor,
                display: 'flex',

                height: imageHeight,

                fontSize: 24 + lerpWidth * 6, // target: 30
                lineHeight: 30 + lerpWidth * 4, // target: 34
                marginBottom: 12 + 0.2 * 20, // target: 32

                textShadowColor: '#0000004A',
                textShadowOffset: { width: 1, height: 1 },
                textShadowRadius: 0,
              }}
            >
              {event._links.self.title || ' '}
              {'\n'}
            </Text>
          )}
          {/* Event info */}
          <View
            style={{
              marginTop: lerpWidth * 2,
              marginBottom: 12 + lerpWidth * 2,
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <View
              style={{
                backgroundColor: '#fff',
                paddingVertical: 4 + lerpWidth * 2,
                paddingHorizontal: 8 + lerpWidth * 2,
                borderRadius: 3 + lerpWidth * 1,
              }}
            >
              <Text
                variant="bodyMedium"
                numberOfLines={2}
                style={{
                  color: colors.eventInfo,
                  includeFontPadding: false,
                  display: 'flex',

                  fontSize: 12 + lerpWidth * 4, // target: 16
                  lineHeight: 20 + lerpWidth * 4, // target: 24
                  fontWeight: '800',
                  marginBottom: 4,
                }}
              >
                {event.subtitle?.toUpperCase() || ' '}
              </Text>
              <Text
                variant="bodyMedium"
                numberOfLines={2}
                style={{
                  color: colors.eventInfo,
                  includeFontPadding: false,
                  display: 'flex',

                  fontSize: 12 + lerpWidth * 4, // target: 16
                  lineHeight: 16 + lerpWidth * 2, // target: 22

                  fontWeight: '500',
                  marginBottom: 2,
                }}
              >
                {startDateStr.toUpperCase()}
                {endDateStr && endDateStr !== startDateStr
                  ? ` - ${endDateStr.toUpperCase()}`
                  : ''}
              </Text>
            </View>
          </View>
          {/* Description */}
          <Text
            variant="bodyMedium"
            numberOfLines={4}
            style={{
              color: colors.description,
              includeFontPadding: false,
              display: 'flex',

              fontSize: 12 + lerpWidth * 4, // target: 16
              lineHeight: 20 + lerpWidth * 2, // target: 22
              ...(colors.textShadow
                ? {
                    textShadowColor: '#0000004A',
                    textShadowOffset: { width: 1, height: 1 },
                    textShadowRadius: 1,
                  }
                : {}),
            }}
          >
            {event.description}
          </Text>
        </View>
        {/* Secondary Buttons */}
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: 20,
            left: 0,
            width: '100%',
            paddingBottom: 5,
          }}
        >
          {/* Timeline Button */}
          <View style={{ width: '50%', paddingRight: 5 }}>
            <Button
              mode="contained"
              buttonColor={colors.secondaryButton}
              textColor={timelineDisabled ? WHITE + 'aa' : WHITE}
              icon={() => (
                <Image
                  source={Icons.white.activity}
                  resizeMode="cover"
                  style={{
                    width: buttonIconSize,
                    height: buttonIconSize,
                    opacity: timelineDisabled ? 0.4 : 1,
                  }}
                />
              )}
              style={{
                width: '100%',
                flexGrow: 1,
                borderRadius: 25,
              }}
              labelStyle={{
                fontWeight: '700',
                fontSize: 12 + lerpWidth * 2,
              }}
              contentStyle={{ height: 44 }}
              disabled={timelineDisabled}
              {...(timelineDisabled ? undefined : timelineLinkProps)}
            >
              {i18n.translate('app.gpnl_card.actions.goto_timeline')}
            </Button>
          </View>
          {/* Ranking Button */}
          <View style={{ width: '50%', paddingLeft: 5 }}>
            <Button
              mode="contained"
              buttonColor={colors.secondaryButton}
              icon={() => (
                <Image
                  source={Icons.white.ranking}
                  resizeMode="cover"
                  style={{
                    width: buttonIconSize,
                    height: buttonIconSize,
                    opacity: rankingDisabled ? 0.4 : 1,
                  }}
                />
              )}
              textColor={rankingDisabled ? WHITE + 'aa' : WHITE}
              style={{
                width: '100%',
                flexGrow: 1,
                borderRadius: 25,
              }}
              labelStyle={{ fontWeight: '700', fontSize: 12 + lerpWidth * 2 }}
              contentStyle={{ height: 44 }}
              disabled={rankingDisabled}
              {...(rankingDisabled ? undefined : rankingLinkProps)}
            >
              {i18n.translate('app.gpnl_card.actions.goto_ranking')}
            </Button>
          </View>
        </View>
        {/* Primary button */}
        <View
          style={{
            bottom: 2,
            left: 0,
            width: '100%',
            padding: 20,
            paddingTop: 12,
          }}
        >
          <Button
            mode="contained"
            buttonColor={colors.primaryButton}
            textColor={votingDisabled ? WHITE + 'aa' : BLACK}
            disabled={votingDisabled}
            labelStyle={{
              fontWeight: '700',
              fontSize: 12 + lerpWidth * 2,
            }}
            contentStyle={{
              flexDirection: 'row-reverse',
              height: 44,
            }}
            icon={() => (
              <Image
                source={
                  votingDisabled
                    ? Icons.white.arrowRight
                    : Icons.black.arrowRight
                }
                resizeMode="cover"
                style={{
                  width: buttonIconSize,
                  height: buttonIconSize,
                  opacity: votingDisabled ? 0.4 : 1,
                }}
              />
            )}
            style={{
              width: hasSponsor ? '75%' : '100%',
              borderRadius: 25,
            }}
            {...(votingDisabled ? undefined : linkProps)}
          >
            {i18n.translate('app.gpnl_card.actions.goto_vote')}
          </Button>
        </View>
        {/* Sponsor sticker */}
        {hasSponsor ? (
          <View style={{ position: 'absolute', bottom: 9, right: 15 }}>
            <Image
              source={require('../../assets/gpvnl/mentos-sticker.png')}
              style={{ width: 136, height: 70 }}
            />
          </View>
        ) : null}
      </Surface>
    </View>
  );
}

function BackgroundImage({
  src,
  color,
  cover = true,
}: {
  src?: string;
  color: string;
  cover?: boolean;
}) {
  const { roundness } = useTheme();

  if (!src) {
    const backgroundColor = sanitizeColor(color, '#FFFFFFFF');

    return (
      <View
        style={[
          StyleSheet.absoluteFill,
          {
            borderRadius: roundness,
            backgroundColor,
          },
        ]}
      />
    );
  }

  const size = IMAGE_PIXEL_RATIO * 346;
  const uri = variantImageUrl(src, 'size');

  return (
    <AnimatedImage
      fadeDuration={0}
      animateOpacity
      transition={{ overshootClamping: true }}
      resizeMode={cover ? 'cover' : 'contain'}
      style={{
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        position: 'absolute',
        borderRadius: roundness,
      }}
      source={{ uri, width: size, height: size }}
    />
  );
}

function ForegroundImage({
  src,
  alt,
  animate,
}: {
  src?: string;
  alt: string;
  animate?: boolean;
}) {
  if (!src) {
    return null;
  }

  const size = IMAGE_PIXEL_RATIO * 346;
  const uri = variantImageUrl(src, 'size');

  return (
    <AnimatedImage
      accessibilityLabel={alt}
      fadeDuration={0}
      animateY={animate ? -30 : undefined}
      animateOpacity
      resizeMode="contain"
      style={{
        width: Platform.select({
          web: undefined,
          android: '100%',
          ios: '100%',
        }),
        maxHeight: '100%',
        height: Platform.select({
          web: undefined,
          android: '100%',
          ios: '100%',
        }),
        maxWidth: Platform.select({
          web: '100%',
          android: undefined,
          ios: undefined,
        }),
        position: 'absolute',
        top: 0,
        left: 0,
      }}
      source={{ uri, width: size, height: Math.round((size / 346) * 280) }}
    />
  );
}

const Icons: Record<
  'black' | 'white',
  Record<string, { uri: string; width: number; height: number }>
> = {
  white: {
    ranking: require('../../assets/icons/white/icon-ranking.png'),
    arrowRight: require('../../assets/icons/white/icon-arrow-right.png'),
    activity: require('../../assets/icons/white/icon-timeline.png'),
  },
  black: {
    ranking: require('../../assets/icons/black/icon-ranking.png'),
    arrowRight: require('../../assets/icons/black/icon-arrow-right.png'),
    activity: require('../../assets/icons/black/icon-timeline.png'),
  },
};
